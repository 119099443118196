import * as Config from '@/config'
import { cleanClone, deepEqual, maskValue } from '@/components/utils/common'

export default {
  props: {
    phoneInfo: Array,
    attributeToSave: String,
    isAdvertPhone: {
      type: Boolean,
      default: false
    },
    categoryName: String,
    itemId: Number,
    item: Object
  },
  data () {
    return {
      currentPhone: null,
      itemPhones: cleanClone(this.phoneInfo),
      phoneInfoMasked: [],
      localKey: null
    }
  },
  watch: {
    itemPhones: {
      handler () {
        this.processPhoneInfo()
        this.handleItemPhonesInput()
      },
      deep: true
    },
    phoneInfo: {
      handler () {
        if (!deepEqual(this.itemPhones, this.phoneInfo)) {
          this.itemPhones = cleanClone(this.phoneInfo)
        }
      },
      deep: true
    },
    currentPhone: {
      handler () {
        if (this.itemPhones.length) {
          this.currentPhone = this.getCurrentPhone
        }
      }
    }
  },
  computed: {
    phoneStatus: {
      get () {
        return this.phoneInfo[this.currentPhoneItemIndex].phone.status
      },
      set (value) {
        this.phoneInfo[this.currentPhoneItemIndex].phone.status = value
      }
    },
    phoneMask () {
      return Config.phoneMask
    },
    getCurrentPhone: {
      get () {
        let currentPhone = null
        if (this.currentPhone) {
          currentPhone = this.currentPhone
        } else if (this.phoneInfo) {
          currentPhone = maskValue(this.phoneInfo[0].phone.phone, this.phoneMask)
        }
        return currentPhone
      },
      set (val) {
        this.currentPhone = val
      }
    },
    currentPhoneItemIndex () {
      let currentIndex = 0
      if (this.phoneInfo.length > 1 && this.getCurrentPhone) {
        this.phoneInfo.forEach((item, index) => {
          if (this.getCurrentPhone && item.phone.phone === this.getCurrentPhone.split(' ').join('')) {
            currentIndex = index
          }
        })
      }
      return currentIndex
    }
  },
  methods: {
    processPhoneInfo () {
      this.phoneInfoMasked = cleanClone(this.phoneInfo)
      if (this.phoneMask) {
        this.phoneInfoMasked.forEach(item => {
          item.phone.phone = item.phone.phone ? maskValue(item.phone.phone, this.phoneMask) : ''
        })
      }
    },
    handlePhoneListInput (data) {
      this.itemPhones = data
      this.localKey++
    },
    handleItemPhonesInput () {
      let object = {}
      object[this.attributeToSave] = this.itemPhones
      this.$emit('input', object)
    }
  }
}
