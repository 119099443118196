<template>
  <v-card
    outlined
    class="mt-1 pa-2"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            :label="$t('advert.description_default')"
            :value="commonDesc"
            @input="$emit('update:commonDesc', $event)"
          ></v-textarea>
        </v-col>
        <v-col cols="8">
          <v-textarea
            outlined
            :label="$t('advert.description_public')"
            :value="publicDesc"
            @input="$emit('update:publicDesc', $event)"
            :error-messages="checkErrors('detail.description_public')"
          ></v-textarea>
        </v-col>
        <v-col cols="4">
          <v-textarea
            outlined
            :value="serviceDesc"
            @input="$emit('update:serviceDesc', $event)"
            :label="$t('advert.description_service')"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <!--note: real === advert для корректной работы AdBlock-->
          <ImageUploader
            title="Фото квартиры"
            :value="images"
            @input="$emit('update:images', $event)"
            url="/image/real"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ImageUploader from '@/components/views/admin/common/ImageUploader'
import formatters from '@/components/mixins/formatters'
import validationErrors from '@/components/mixins/validationErrors'

export default {
  mixins: [formatters, validationErrors],
  name: 'AdvertDescriptions',
  components: { ImageUploader },
  props: {
    commonDesc: String,
    publicDesc: String,
    serviceDesc: String,
    images: Array
  },
  inject: ['$v']
}
</script>

<style scoped>

</style>
