<template>
  <BaseRelatedTable
    v-model="isOpen"
    :table-title="'Потенциальные клиенты'"
    :min-width="'850px'"
    left
  >
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        @click="handleOpen"
        icon
        :class="getButtonClass"
        :disabled="loading"
        dark
      >
        <v-icon large>mdi-face-agent</v-icon>
      </v-btn>
    </template>
    <template #content>
      <BaseTable
        :options.sync="localOptions"
        :loading="loading"
        :headers="headers"
        :items="getItems.models"
        :items-length="getItems.count"
        @detail="$emit('detail', $event)"
      >
        <template #item="{item}">
          <ListItem
            name="item"
            :item="item"
            :advert-id="itemId"
          />
        </template>
      </BaseTable>
    </template>
  </BaseRelatedTable>
</template>

<script>
import BaseRelatedTable from '@/components/base/BaseRelatedTable'
import relatedTable from '@/components/mixins/relatedTable'
import headers from '@/components/views/admin/advert/Detail/Header/AdvertClients/headers'
import BaseTable from '@/components/views/admin/common/BasePhone/RelatedCategory/BaseTable'
import ListItem from '@/components/views/admin/advert/Detail/Header/AdvertClients/ListItem'
import { deepEqual } from '@/components/utils/common'

export default {
  mixins: [relatedTable],
  name: 'AdvertClients',
  components: { BaseRelatedTable, BaseTable, ListItem },
  props: {
    itemId: {
      type: Number,
      default: null
    },
    localKey: Number
  },
  data () {
    return {
      isOpen: false,
      url: '/clients-advert',
      options: {
        sortBy: [],
        sortDesc: [false, false],
        page: 1,
        itemsPerPage: 20
      },
      headers: headers
    }
  },
  watch: {
    localKey: {
      async handler (newValue, oldValue) {
        if (!deepEqual(oldValue, newValue)) {
          return setTimeout(this.fillTable, 3000) // таймаут стоит для синхронизации сохранения объявления и обновления данных
        }
      },
      deep: true
    }
  },
  computed: {
    getButtonClass () {
      return this.getItems.hasUnviewed ? 'success' : 'primary'
    }
  },
  methods: {
    getFilters () {
      return { advertId: [this.itemId] }
    },
    async handleOpen () {
      this.isOpen = true
      return this.fillTable()
    }
  },
  mounted () {
    this.fillTable()
  }
}
</script>

<style scoped>

</style>
