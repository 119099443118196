<template>
  <v-select
    :value="currentPhone"
    @input="$emit('update:currentPhone', $event)"
    :items="phoneInfoMasked"
    item-text="phone.phone"
    :label="$t('advertPhone.phone')"
    class="pa-2"
  >
    <template v-slot:prepend>
      <v-tooltip
        v-model="showTooltip"
        top
      >
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            x-small
            icon
            v-clipboard:copy="currentPhone ? currentPhone : ''"
            v-clipboard:success="onCopy"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Скопировано</span>
      </v-tooltip>
    </template>
    <template v-slot:selection="{ item }">
      <span :class="phoneStatusClass(phoneStatus)">{{item.phone.phone}}</span>
    </template>
    <template v-slot:item="{ item }">
      <span :class="phoneStatusClass(item.phone.status)">{{item.phone.phone}}</span>
    </template>
  </v-select>
</template>

<script>
import phoneSelect from '@/components/mixins/phoneSelect'
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [phoneSelect, formatters],
  name: 'PhoneSelect'
}
</script>

<style scoped>

</style>
