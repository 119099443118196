<template>
  <div class="caption pr-4 flex-grow-1">
    <b>Источники:</b>
    <v-menu
      :close-on-content-click="false"
      :close-on-click="false"
      :nudge-width="100"
      offset-x
      ref="create"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          x-small
          icon
          v-on="on"
          class="blue lighten-4 ml-1"
        >
          <v-icon x-small>mdi-plus</v-icon>
        </v-btn>
      </template>
      <AdvertExternalDetail
        :value="newAdvertExternal"
        @change="handleChange($event)"
        @close="handleInternalClose('create')"
      />
    </v-menu>
    <v-btn
      x-small
      icon
      class="blue lighten-4 ml-1"
      @click="copyArray"
    >
      <v-icon x-small>mdi-content-copy</v-icon>
    </v-btn>
    <v-btn
      x-small
      icon
      class="blue lighten-4 ml-1"
      @click="pasteArray"
    >
      <v-icon x-small>mdi-content-paste</v-icon>
    </v-btn>
    <!--Источники инпуты (начало)-->
    <span
      class="ma-0"
      v-for="(item, index) in localValue"
      :key="`${key}${index}`"
    >
      <v-menu
        max-width="200px"
        :close-on-content-click="false"
        :close-on-click="false"
        offset-x
        ref="edit"
      >
        <template v-slot:activator="{ on }">
          <div class="d-flex justify-space-between">
            <v-text-field
              readonly
              v-on="on"
              v-model="item.source_id"
              class="pa-0 mt-0"
              :prefix="formatValue('advertExternalIds.source', item.source)"
              hide-details
            ></v-text-field>
            <v-btn
              :disabled="!item.source_link"
              class="mt-3"
              x-small
              color="white"
              elevation="0"
              @click="handlePushLink(index)"
            >
              <v-icon small>
                mdi-link
              </v-icon>
            </v-btn>
            <v-btn
              class="mt-3"
              x-small
              color="white"
              elevation="0"
              @click="handleRemove(index)"
            >
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </template>
        <AdvertExternalDetail
          :value="item"
          @change="handleChange($event, index)"
          @close="handleInternalClose('edit', index)"
        />
      </v-menu>
    </span>
    <!--Источники инпуты (конец)-->
  </div>
</template>

<script>
import { cleanClone, deepEqual } from '@/components/utils/common'
import AdvertExternalId from '@/components/structures/advertExternalId'
import AdvertExternalDetail from '@/components/views/admin/advert/Detail/AdvertInfo/AdvertExternalDetail'
import formatters from '@/components/mixins/formatters'

export default {
  name: 'AdvertExternalId',
  components: { AdvertExternalDetail },
  mixins: [formatters],
  props: {
    value: Array
  },
  data () {
    return {
      localValue: this.value,
      key: true
    }
  },
  computed: {
    newAdvertExternal () {
      return new AdvertExternalId()
    },
    arrayForPaste () {
      return this.$store.getters['getCopiedArray']
    }
  },
  methods: {
    copyArray () {
      this.$store.dispatch('copyArray', this.localValue)
    },
    pasteArray () {
      let uniqueSources = new Set(this.localValue.map(item => item.source + item.source_id))

      let newItems = this.arrayForPaste.filter(item => {
        let uniqueKey = item.source + item.source_id
        if (uniqueSources.has(uniqueKey)) {
          return false
        } else {
          uniqueSources.add(uniqueKey)
          return true
        }
      }).map(item => ({
        id: null,
        source: item.source,
        source_id: item.source_id,
        source_link: item.source_link
      }))

      this.localValue = [...this.localValue, ...newItems]

      this.$emit('input', this.localValue)
    },
    handleChange (data, index = null) {
      if (index !== null) {
        this.localValue[index] = data
      } else {
        this.localValue.push(data)
      }
      this.$emit('input', this.localValue)
      this.key = !this.key
    },
    handleRemove (index) {
      this.localValue.splice(index, 1)
      this.$emit('input', this.localValue)
    },
    //note: закрывает меню из ребенка без использования v-model
    handleInternalClose (name, index = null) {
      let ref = this.$refs[name]
      if (index !== null) {
        ref = ref[index]
      }
      ref.isActive = false
    },
    handlePushLink (index) {
      window.open(this.localValue[index].source_link)
    }
  },
  watch: {
    value: {
      handler () {
        if (this.value.length && !deepEqual(this.value, this.localValue)) {
          this.localValue = cleanClone(this.value)
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
