<template>
  <v-dialog
    v-model="value"
    persistent
    max-width="390px"
  >
    <v-card
      outlined
      class="pa-5"
    >
      <v-card-title class="headline">Подтвердите удаление</v-card-title>
      <v-card-text>
        {{text}}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          @click="isDialogOpen = false"
        >
          Отменить удаление
        </v-btn>
        <v-btn
          class="primary"
          small
          @click="handleDelete"
        >
          Да, удалить из бд
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDeleteDialog',
  props: {
    value: Boolean,
    itemId: Number,
    text: String
  },
  computed: {
    isDialogOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleDelete () {
      this.$emit('delete', this.itemId)
      this.isDialogOpen = false
    }
  }
}
</script>

<style scoped>
