<template>
  <v-menu
    v-model="isOpen"
    content-class="isOpenDialogTable"
    :close-on-content-click="false"
    max-width="100%"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        x-small
        icon
        :class="getButtonClass"
        class="pa-0 mb-1 d-block"
        :disabled="loading"
        dark
      >
        <v-icon small>mdi-account-search-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="primary white_text"
      >
        Поиск совпадений по клиентам на этом номере
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click.stop="isOpen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="scrollIfSoBig">
        <BaseTable
          :options.sync="localOptions"
          :loading="loading"
          :headers="headers"
          :items="getItems.models"
          :items-length="getItems.count"
          @detail="$emit('detail', $event)"
        >
          <template #item="{item}">
            <ListItem
              name="item"
              :item="item"
            />
          </template>
        </BaseTable>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import BaseTable from '@/components/views/admin/common/BasePhone/RelatedCategory/BaseTable'
import relatedTable from '@/components/mixins/relatedTable'
import ListItem from '@/components/views/admin/clients/Detail/Phone/ListItem'
import headers from '@/components/views/admin/clients/Detail/Phone/headers'

export default {
  mixins: [relatedTable],
  name: 'RelatedClients',
  components: { ListItem, BaseTable },
  props: {
    phones: Array,
    itemId: {
      type: Number,
      default: null
    },
    clientCategory: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isOpen: false,
      url: '/client',
      options: {
        sortBy: ['updated_at'],
        sortDesc: [false, false],
        page: 1,
        itemsPerPage: 20
      },
      headers: headers
    }
  },
  computed: {
    canFillTable () {
      return this.phones.length
    }
  },
  methods: {
    getFilters () {
      return { phoneId: this.getPhoneIds(), excludeIds: [this.itemId], clientCategory: this.clientCategory } /* clientCategory: 1793 - статус покупатель */
    },
    getPhoneIds () {
      let phoneIds = []
      if (this.canFillTable) {
        this.phones.forEach(item => {
          phoneIds.push(item.phone.id)
        })
      }
      return phoneIds
    }
  },
  mounted () {
    this.fillTable()
  }
}
</script>

<style scoped>
  .white_text {
    color: white !important;
  }
  .scrollIfSoBig {
    max-height: 35vh;
    overflow: auto;
  }
</style>
