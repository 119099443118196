<template>
  <div>
    <v-row>
      <v-col>
        <v-menu
          bottom
          left
          nudge-width="300"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="loading"
              icon
              v-on="on"
            >
              <v-icon
                large
                :color="buttonClass"
              >
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in items"
              :key="item.title"
            >
              <v-list-item-title>
                <ListAdvertCopy
                  :title="item.title"
                  :filters="item.filters"
                  :advert-id="item.advertId"
                  :items="item.tableItems"
                  :button-class="item.buttonClass"
                  :on-click="item.onClick"
                  @detail="$emit('detail', $event)"
                />
              </v-list-item-title>
            </v-list-item>
            <SearchByDescDefault
              :id="staticProps.id"
              :common-desc="staticProps.commonDesc"
              @detail="$emit('detail', $event)"
            />
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListAdvertCopy from '@/components/views/admin/advert/Detail/Header/AdvertCopy/ListAdvertCopy'
import SearchByDescDefault
  from '@/components/views/admin/advert/Detail/Header/AdvertCopy/CustomSearches/SearchByDescDefault'
import { deepEqual } from '@/components/utils/common'

export default {
  name: 'SearchAdvertCopy',
  components: { SearchByDescDefault, ListAdvertCopy },
  props: {
    //пропсы для изменения ключа и динамических таблиц дублей
    propsToRefresh: {
      address: Object,
      floor: Number,
      roomCount: Number,
      areaCommon: String,
      advertExternalIds: Array
    },
    //пропсы, которые не идут в вотч
    staticProps: {
      id: Number,
      commonDesc: String
    },
    localKey: Number
  },
  data () {
    return {
      items: [],
      loading: false,
      buttonClass: 'primary'
    }
  },
  computed: {
    addressFilters () {
      let addressFilter = { streetId: null, buildingNumberId: null }
      if (this.propsToRefresh.address) {
        addressFilter.streetId = this.propsToRefresh.address.street.id
        addressFilter.buildingNumberId = this.propsToRefresh.address.id
      }
      return addressFilter
    },
    externalIdFilters () {
      //todo: рефактор
      let externalIdFilters = [{ source: 'no source', sourceId: 'no source id' }]
      let filteredExternalIds = this.propsToRefresh.advertExternalIds.filter(externalId => externalId.source_id)
      if (filteredExternalIds.length) {
        externalIdFilters = filteredExternalIds
          .map(externalId => ({
              source: externalId.source,
              sourceId: externalId.source_id
            })
          )
      }
      return externalIdFilters
    }
  },
  watch: {
    propsToRefresh: {
      handler () {
        this.getItems()
        this.getItemsForTables()
      },
      deep: true,
      immediate: true
    },
    localKey: {
      async handler (newValue, oldValue) {
        if (!deepEqual(oldValue, newValue)) {
          return setTimeout(this.fillTable, 3000) // таймаут стоит для синхронизации сохранения объявления и обновления данных
        }
      },
      deep: true
    }
  },
  methods: {
    ranges (value, factor = null) {
      let ranges = [null, null]
      if (value) {
        value = parseFloat(value)
        if (factor) {
          let delta = Math.round(value * factor)
          ranges[0] = value - delta
          ranges[1] = value + delta
        } else {
          ranges[0] = value
          ranges[1] = value
        }
      }
      return ranges
    },
    async getItemsForTables () {
      this.loading = true
      for (let item in this.items) {
        if (this.items.hasOwnProperty(item) && this.items[item].hasOwnProperty('url')) {
          this.items[item].loading = true
          let response = await this.$store.dispatch('table/fillTable', {
            url: this.items[item].url,
            rawData: {
              options: this.items[item].options,
              filters: { ...this.items[item].filters, excludeIds: [this.items[item].advertId] }
            }
          })
          if (response) {
            this.items[item].tableItems = response
            if (this.items[item].tableItems.count) {
              this.items[item].buttonClass = 'success'
              this.buttonClass = 'success'
            } else {
              this.items[item].buttonClass = 'grey'
              this.buttonClass = 'primary'
            }
          }
          this.items[item].loading = false
        }
      }
      this.loading = false
      return true
    },
    async getItems () {
      this.items = [
        {
          title: 'дублей',
          filters: {
            ...this.addressFilters,
            floor: this.ranges(this.propsToRefresh.floor),
            areaCommon: this.ranges(this.propsToRefresh.areaCommon, 0.1),
            rooms: this.ranges(this.propsToRefresh.roomCount)
          },
          advertId: this.staticProps.id,
          tableItems: {
            count: 0,
            models: []
          },
          url: '/advert',
          options: {
            sortBy: ['adv_status', 'delay_until'],
            sortDesc: [false, false],
            page: 1,
            itemsPerPage: 20
          }
        },
        {
          title: 'по id источника',
          filters: { advertExternalIds: this.externalIdFilters },
          advertId: this.staticProps.id,
          tableItems: {
            count: 0,
            models: []
          },
          url: '/advert',
          options: {
            sortBy: ['adv_status', 'delay_until'],
            sortDesc: [false, false],
            page: 1,
            itemsPerPage: 20
          }
        },
        {
          title: 'по адресу',
          filters: this.addressFilters,
          advertId: this.staticProps.id,
          onClick: true
        }
      ]
    }
  },
  async mounted () {
    return this.getItems()
  }
}
</script>

<style scoped>

</style>
