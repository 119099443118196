export default [
  {
    text: 'ID',
    align: 'left',
    sortable: true,
    value: 'id',
    width: '80px'
  },
  {
    text: 'Дата',
    align: 'left',
    sortable: true,
    value: 'updated_at',
    width: '80px'
  },
  {
    text: 'Телефон',
    align: 'left',
    sortable: true,
    value: 'phone',
    width: '100px'
  },
  {
    text: 'Отв. агент',
    align: 'left',
    sortable: true,
    value: 'parent_id',
    width: '100px'
  },
  {
    text: 'Ст человека',
    align: 'left',
    sortable: true,
    value: 'impression',
    width: '60px'
  },
  {
    text: 'Ст адекв тр',
    align: 'left',
    sortable: true,
    value: 'success_rate',
    width: '60px'
  },
  {
    text: 'Ст лояльности',
    align: 'left',
    sortable: true,
    value: 'deal_step',
    width: '60px'
  }
]
