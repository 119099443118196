<template>
  <div>
    <v-menu
      v-model="isOpen"
      content-class="isOpenDialogTable"
      :close-on-content-click="false"
      :close-on-click="false"
      max-width="100%"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          @click="getTable"
          block
          :color="buttonClass"
          v-on="on"
          dark
        >
          Поиск {{title}}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="primary white_text">
          Поиск {{title}}
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click.stop="isOpen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="scrollIfSoBig">
          <Table
            :options.sync="localOptions"
            :loading="loading"
            :items="getItems.models"
            :items-length="getItems.count"
            @detail="$emit('detail', $event)"
          />
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Table from '@/components/views/admin/advert/Detail/Header/AdvertCopy/ListAdvertCopy/Table'
import relatedTable from '@/components/mixins/relatedTable'

export default {
  mixins: [relatedTable],
  name: 'ListAdvertCopy',
  components: { Table },
  props: {
    title: String,
    filters: Object,
    advertId: Number,
    onClick: Boolean
  },
  data () {
    return {
      isOpen: false,
      url: '/advert',
      options: {
        sortBy: ['adv_status', 'delay_until', 'updated_at'],
        sortDesc: [false, false],
        page: 1,
        itemsPerPage: 20
      }
    }
  },
  methods: {
    getFilters () {
      return { ...this.filters, excludeIds: [this.advertId] }
    },
    getTable () {
      if (this.onClick) {
       this.debounceFillTable()
      }
    }
  }
}
</script>

<style scoped>
  .white_text {
    color: white !important;
  }
  .scrollIfSoBig {
    max-height: 35vh;
    overflow: auto;
  }
</style>
