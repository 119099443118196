import { maskValue } from '@/components/utils/common'
import PhoneStructureFactory from '@/components/structures/phoneStructureFactory'
import * as Config from '@/config'

export default {
  props: {
    phoneInfo: Array,
    currentPhone: String,
    categoryName: String
  },
  data () {
    return {
      localPhones: this.phoneInfo,
      isOpenPhoneMenu: false,
      isSavingPhone: false
    }
  },
  watch: {
    phoneInfo: {
      handler () {
        this.localPhones = this.phoneInfo
      },
      deep: true
    }
  },
  computed: {
    phoneMask () {
      return Config.phoneMask
    }
  },
  methods: {
    // note: видимо при работе с массивами некорректно обрабатывается computed. Перенес newModel в методы
    newModel () {
      return new PhoneStructureFactory(this.categoryName)
    },
    handleAddPhone () {
      this.localPhones.push(this.newModel())
      this.isOpenPhoneMenu = true
    },
    handleRemovePhone (index) {
      this.localPhones.splice(index, 1)
    },
    async savePhones () {
      if (this.canSavePhones()) {
        this.isSavingPhone = true
        const response = await this.$store.dispatch('detail/save', {
          url: '/phone/check',
          data: this.localPhones
        })
        if (response) {
          this.localPhones = response
          this.$emit('update:currentPhone', maskValue(this.localPhones[0].phone.phone, this.phoneMask))
          this.$emit('input', this.localPhones)
          this.isOpenPhoneMenu = false
        }
        this.isSavingPhone = false
      } else {
        this.$store.commit('systemMessages/addError', 'Неверный формат номера телефона')
      }
    },
    canSavePhones () {
      return !this.localPhones.find(item => !item.phone.phone)
    }
  }
}
