<template>
  <v-dialog
    v-model="value"
    persistent
    max-width="390px"
  >
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column justify-lg-space-between"
          >
            <v-select
              :value="itemStatus"
              @input="$emit('update:itemStatus', $event)"
              :items="items"
              item-value="id"
              item-text="value"
              :label="text"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
            @click="isDialogOpen = false"
          >
            Отменить
          </v-btn>
          <v-btn
            class="primary"
            @click="handleSaveDetailAs"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SaveAsButton',
  props: {
    value: Boolean,
    itemStatus: Number,
    items: Array,
    text: String,
    disabled: Boolean
  },
  computed: {
    isDialogOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleSaveDetailAs () {
      this.$emit('saveAs')
      this.isDialogOpen = false
    }
  }
}
</script>

<style scoped>

</style>
