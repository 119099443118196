<template>
  <tr>
    <td @click="detail">{{ item.id }}</td>
    <td @click="detail">{{ item.username }}</td>
    <td @click="detail">
      <div
        v-for="phone in item.userPhones"
        :key="phone.phone.phone"
        :class="phoneStatusClass(phone.phone.status)"
        style="white-space:nowrap"
      >
        {{ maskValue(phone.phone.phone, '8 ### ### ## ##') }}
      </div>
    </td>
    <td>{{ formatValue('users.agents', item.parent_id) }}</td>
    <td>
      <v-checkbox
        v-model="localUsersAdvert.viewed"
      />
    </td>
    <td>
      <v-checkbox
        v-model="localUsersAdvert.approved"
      />
    </td>
    <td>
      <v-checkbox
        v-model="localUsersAdvert.offered"
      />
    </td>
    <td style="max-width: 15px">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            class="mb-2"
            icon
            color="primary"
            :loading="isSavingData"
            @click="saveCompilation"
          >
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
        </template>
        Сохранить
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import tabActions from '@/components/mixins/tabActions'

export default {
  name: 'ListItem',
  data () {
    return {
      isSavingData: false,
      localUsersAdvert: {
        viewed: false,
        approved: false,
        offered: false
      },
      localId: null
    }
  },
  props: {
    item: Object,
    advertId: Number
  },
  mixins: [formatters, tabActions],
  computed: {
    getProfileId () {
      return this.$store.getters['auth/getProfile'].id
    }
  },
  methods: {
    detail () {
      this.add('clients', 'detail', {
        title: null,
        id: null,
        client_id: null,
        agent_id: null,
        requestParams: {
          id: this.item.id
        }
      })
      if (this.$route.name !== 'clients') {
        this.$router.push({ name: 'clients' })
      }
    },
    itemStatusUpdate (response) {
      if (response) {
        this.localUsersAdvert.viewed = response.viewed
        this.localUsersAdvert.offered = response.offered
        this.localUsersAdvert.approved = response.approved
        this.localId = response.id
      } else if (this.item.usersAdvert.length) {
        let newItemStatus = this.item.usersAdvert.find(item => item.advert_id === this.advertId && (item.agent_id === null ? true : item.agent_id === this.getProfileId))
        if (newItemStatus) {
          this.localUsersAdvert.viewed = newItemStatus.viewed
          this.localUsersAdvert.offered = newItemStatus.offered
          this.localUsersAdvert.approved = newItemStatus.approved
          this.localId = newItemStatus.id
        }
      }
    },
    async saveCompilation () {
      if (!this.isSavingData) {
        this.isSavingData = true
        const response = await this.$store.dispatch('detail/save', {
          url: '/clients-advert/detail',
          data: {
            id: this.localId,
            agent_id: this.getProfileId,
            client_id: this.item.id,
            advert_id: this.advertId,
            viewed: this.localUsersAdvert.viewed,
            approved: this.localUsersAdvert.approved,
            offered: this.localUsersAdvert.offered
          }
        })
        if (response) {
          this.itemStatusUpdate(response)
        }
        this.isSavingData = false
      }
      return true
    }
  },
  mounted () {
    this.itemStatusUpdate()
  }
}
</script>

<style scoped>

</style>
