<template>
  <div>
    <v-card
      v-if="!buildingExists"
      outlined
      class="mt-1 pa-2"
    >
      <div class="title text-center">Не указан дом или его нет в базе данных</div>
      <v-card-text class="text-center">
        <v-btn
          class="primary"
          small
          @click="handleAddBuilding"
          :disabled="!$can('agent', 'createBuilding')"
        >Создать дом
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card
      v-else
      outlined
      class="mt-1 pa-2"
      :class="value.is_verified ? '' : 'not-verified'"
    >
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="districtCustom"
                  disabled
                  :label="$t('building.district_custom')"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="buildingType"
                  disabled
                  class="text_field-red-text"
                  :label="$t('building.building_type')"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="value.build_year"
                  disabled
                  :label="$t('building.build_year')"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="subDistrict"
                  disabled
                  :label="$t('building.subdistrict')"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  disabled
                  :label="$t('building.total_floors')"
                  :value="totalFloors"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="buildingMaterial"
                  disabled
                  :label="$t('building.building_material')"
                ></v-text-field>
              </v-col>
              <!--<v-col cols="12">
                <v-text-field
                  v-model="buildingOverlap"
                  disabled
                  label="Перекрытия"
                ></v-text-field>
              </v-col>-->
              <v-col cols="12">
                <v-text-field
                  v-model="apartmentComplex"
                  disabled
                  :label="$t('building.apartment_complex')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="builder"
                  disabled
                  :label="$t('building.builder')"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  :value="value.description_service"
                  outlined
                  readonly
                  rows="3"
                  :label="$t('building.description_service')"
                ></v-textarea>
              </v-col>
              <v-col
                cols="6"
                class="text-center"
              >
                <v-btn
                  class="primary"
                  dark
                  small
                  text
                  @click="handleEditBuilding"
                >
                  Редактировать карточку дома
                  <v-icon
                    small
                    class="pl-2"
                  >
                    mdi-lead-pencil
                  </v-icon>
                </v-btn>
                <div class="title pt-3">{{value.is_ready ? 'Дом сдан' : 'Дом не сдан'}}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="3"
            v-if="value.mainBuildingImage"
          >
            <div
              class="thumb-image"
            >
              <v-menu
                absolute
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-img
                    :src="axios.defaults.baseURL + '/cache/images/thumbnails/' + value.mainBuildingImage.image.path + value.mainBuildingImage.image.file_name"
                    class="grey darken-4 img-fluid"
                    v-on="on"
                  ></v-img>
                </template>
                <v-card>
                  <v-img
                    :src="axios.defaults.baseURL + '/images/' + value.mainBuildingImage.image.path + value.mainBuildingImage.image.file_name"
                    class="grey darken-4 img-fluid"
                    width="600"
                  ></v-img>
                </v-card>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import tabActions from '@/components/mixins/tabActions'

export default {
  mixins: [formatters, tabActions],
  name: 'BuildingInfo',
  props: {
    value: Object,
    advertFloors: Number,
    advertBuildingMaterial: Number
  },
  computed: {
    buildingExists () {
      return this.value.mainBuildingAddress.id
    },
    districtCustom () {
      return this.formatValue('building.district_custom', this.value.district_custom)
    },
    buildingOverlap () {
      return 'Деревянные'
    },
    buildingType () {
      return this.formatValue('building.building_type', this.value.building_type)
    },
    subDistrict () {
      return this.formatValue('building.subdistrict', this.value.subdistrict)
    },
    apartmentComplex () {
      return this.formatValue('building.apartment_complex', this.value.apartment_complex)
    },
    buildingMaterial () {
      return this.formatValue('building.building_material', this.value.building_material ? this.value.building_material : this.advertBuildingMaterial)
    },
    totalFloors () {
      return this.value.total_floors !== null ? this.value.total_floors : this.advertFloors
    },
    builder () {
      return this.formatValue('building.builder', this.value.builder)
    }
  },
  watch: {
    'value.mainBuildingAddress.building_id': {
      async handler (val) {
        if (val) {
          await this.fillBuilding()
        }
      }
    }
  },
  methods: {
    async fillBuilding () {
      if (this.value.mainBuildingAddress.building_id) {
        let response = await this.$store.dispatch('detail/fillDetail', { url: '/building/info/' + this.value.mainBuildingAddress.building_id })
        if (response) {
          this.$emit('input', response)
        }
      }
    },
    handleEditBuilding () {
      this.add('building', 'detail', { requestParams: { id: this.value.id } })
      this.$router.push({ name: 'building' })
    },
    handleAddBuilding () {
      this.add('building', 'detail', { requestParams: { id: null } })
      this.$router.push({ name: 'building' })
    }
  }
}
</script>

<style>
  .text_field-red-text .v-input__control .v-input__slot .v-text-field__slot input {
    color: red !important;
  }
  .not-verified {
    border: 2px solid #ff1e1e !important;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.2)
  }
  .thumb-image {
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  }
  .thumb-image img {
    width: 1333%;
  }
</style>
