<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>
          mdi-information-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="scrollIfSoBig">
        <p
          v-for="(historyItem, index) in attribute"
          :key="index"
        >
          {{historyItem}}
        </p>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'AttributeHistory',
  props: {
    attribute: Array
  }
}
</script>

<style scoped>
.scrollIfSoBig {
  max-height: 45vh;
  overflow: auto;
}
</style>
