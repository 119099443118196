<template>
  <tr
    @click="detail"
  >
    <td>{{item.id}}</td>
    <td class="date">{{item.updated_at ? timestampToDatetime(item.updated_at) : ''}}</td>
    <td>
      <div
        v-for="phone in item.userPhones"
        :key="phone.phone.phone"
        :class="phoneStatusClass(phone.phone.status)"
        style="white-space:nowrap"
      >
        {{maskValue(phone.phone.phone, '8 ### ### ## ##')}}
      </div>
    </td>
    <td>{{formatValue('users.agents', item.parent_id)}}</td>
    <td>{{formatValue('user.impression', item.impression)}}</td>
    <td>{{formatValue('user.success_rate', item.success_rate)}}</td>
    <td>{{formatValue('user.deal_step', item.deal_step)}}</td>
    <td></td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import tabActions from '@/components/mixins/tabActions'

export default {
  name: 'ListItem',
  props: {
    item: Object
  },
  mixins: [formatters, tabActions],
  methods: {
    detail () {
      this.add('clients', 'detail', {
        title: null,
        id: null,
        client_id: null,
        agent_id: null,
        requestParams: {
          id: this.item.id
        }
      })
      if (this.$route.name !== 'clients') {
        this.$router.push({ name: 'clients' })
      }
    }
  }
}
</script>

<style scoped>

</style>
