<template>
  <v-card max-width="200">
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="pa-0"
        >
          <v-select
            v-model="localValue.name"
            :items="advertExtraStatuses"
            :label="$t('advert.advert_extra')"
            item-value="id"
            item-text="value"
            class="pa-2"
            hide-details
          ></v-select>
          <v-checkbox
            v-model="localValue.state"
            :label="$t('advert.hasBurden')"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          class="pa-0"
        >
          <v-textarea
            outlined
            v-model="localValue.description"
            :label="$t('advertExtra.description')"
            class="pa-2"
            hide-details
          ></v-textarea>
        </v-col>
        <!--todo: стилизовать кнопки-->
        <v-col
          cols="12"
          class="pa-0 text-right"
        >
          <v-btn
            color="primary"
            dark
            x-small
            @click="handleSave"
            class="mr-1"
          >
            {{$t('base.save')}}
          </v-btn>
          <v-btn
            x-small
            @click="$emit('close')"
          >
            {{$t('base.cancel')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { cleanClone } from '@/components/utils/common'

export default {
  name: 'AdvertExtraDetail',
  props: {
    value: Object
  },
  computed: {
    advertExtraStatuses () {
      return this.$store.getters['serviceData/optionsList']('advertExtra.name', false)
    }
  },
  data () {
    return {
      localValue: cleanClone(this.value)
    }
  },
  watch: {
    value: {
      handler () {
        this.localValue = cleanClone(this.value)
      },
      deep: true
    }
  },
  methods: {
    handleSave () {
      this.$emit('change', this.localValue)
      this.localValue = cleanClone(this.value)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
