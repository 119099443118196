<template>
  <v-col
    cols="2"
    class="pt-0 pb-0"
  >
    <v-text-field
      :value="formattedPrice"
      @change="handlePriceInput($event)"
      :label="$t('advert.price')"
    >
    </v-text-field>
  </v-col>
</template>

<script>
import formatters from '@/components/mixins/formatters'

export default {
  name: 'Price',
  mixins: [formatters],
  props: {
    price: Number,
    priceHistory: Array
  },
  computed: {
    formattedPrice () {
      if (this.price) {
        return this.formatPrice(this.price)
      } else {
        return null
      }
    }
  },
  methods: {
    handlePriceInput (data) {
      let priceHistory = this.priceHistory
      if (!Array.isArray(priceHistory)) {
        priceHistory = []
      }
      if (this.price) {
        priceHistory.push(`${(new Date()).toLocaleString('ru-RU')} - ${this.formattedPrice} т.р. ${this.$store.getters['auth/getName']}`)
      }
      this.$emit('update:price', this.reformatPrice(data))
      this.$emit('update:priceHistory', priceHistory)
    }
  }
}
</script>

<style scoped>

</style>
