export default {
  props: {
    currentPhone: String,
    phoneInfoMasked: Array,
    phoneStatus: Number
  },
  data () {
    return {
      showTooltip: false
    }
  },
  methods: {
    onCopy () {
      this.showTooltip = true
      setTimeout(() => {
        this.showTooltip = false
      }, 1000)
    }
  }
}
