class AdvertExtra {
  constructor () {
    this.id = null
    this.name = null
    this.description = null
    this.state = true
  }
}

export default AdvertExtra
