<template>
  <BaseRelatedTable
    v-model="isOpen"
    :table-title="'Совпадения в таблице Номера телефонов'"
  >
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        @click="isOpen = true"
        icon
        x-small
        :class="getButtonClass"
        :disabled="loading"
        dark
      >
        <v-icon small>mdi-phone-check-outline</v-icon>
      </v-btn>
    </template>
    <template #content>
      <BaseTable
        :options.sync="localOptions"
        :loading="loading"
        :headers="headers"
        :items="getItems.models"
        :items-length="getItems.count"
        @detail="$emit('detail', $event)"
      >
        <template #item="{item}">
          <tr
            @click="detail(item.id)"
          >
            <td>{{ item.id }}</td>
            <td>{{ maskValue(item.phone, '8 ### ### ## ##') }}</td>
            <td>{{ item.name }}</td>
            <td>{{ formatValue('phone.status', item.status) }}</td>
            <td><div
              v-for="status in item.owner_status"
              :key="Number(status)"
            >
              {{ status ? formatValue('phone.owner_status', Number(status)) : '-' }}
            </div></td>
            <td>{{ item.description }}</td>
          </tr>
        </template>
      </BaseTable>
    </template>
  </BaseRelatedTable>
</template>

<script>
import BaseRelatedTable from '@/components/base/BaseRelatedTable'
import BaseTable from '@/components/views/admin/common/BasePhone/RelatedCategory/BaseTable'
import relatedTable from '@/components/mixins/relatedTable'
import headers from '@/components/views/admin/phone/List/header'
import formatters from '@/components/mixins/formatters'
import tabActions from '@/components/mixins/tabActions'

export default {
  mixins: [formatters, relatedTable, tabActions],
  name: 'RelatedPhones',
  props: {
    phones: Array
  },
  components: { BaseRelatedTable, BaseTable },
  data () {
    return {
      isOpen: false,
      url: '/phone',
      options: {
        sortBy: ['updated_at'],
        sortDesc: [false, false],
        page: 1,
        itemsPerPage: 20
      },
      headers: headers
    }
  },
  methods: {
    getFilters () {
      return { phoneId: this.getPhoneIds() }
    },
    detail (id) {
      this.add('phone', 'detail', {
        requestParams: {
          id: id
        }
      })
      if (this.$route.name !== 'phone') {
        this.$router.push({ name: 'phone' })
      }
    },
    getPhoneIds () {
      let phoneIds = []
      if (this.canFillTable) {
        this.phones.forEach(item => {
          phoneIds.push(item.phone.id)
        })
      }
      return phoneIds
    }
  },
  mounted () {
    this.fillTable()
  }
}
</script>

<style scoped>

</style>
