<template>
  <v-menu
    v-model="isOpenPhoneMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div class="d-block text-center">
        <h4>добавьте номер телефона</h4>
        <v-btn
          dark
          class="primary pa-2 mt-3"
          small
          @click="handleAddPhone()"
        >
          Добавте номер
          <v-icon
            small
            class="ml-2"
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-menu>
</template>

<script>
import phoneList from '@/components/mixins/phoneList'

export default {
  mixins: [phoneList],
  name: 'EmptyPhone',
  props: {
    categoryName: String
  }
}
</script>

<style scoped>

</style>
