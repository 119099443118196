<template>
  <v-checkbox
    class="checkbox"
    v-model="localIsPublished"
    @change="$emit('update:isPublished', $event)"
    :false-value="0"
    :true-value="1"
    label="Публиковать на сайте"
    :disabled="!$can('agent', 'editIsPublishedStatus') || isDisabled"
  ></v-checkbox>
</template>

<script>
export default {
  name: 'PublishedCheckbox',
  props: {
    isPublished: Number,
    publishedAt: Number,
    isDisabled: Boolean
  },
  data () {
    return {
      localIsPublished: this.isPublished
    }
  },
  watch: {
    isPublished (val) {
      let timestamp = null
      if (val) {
        timestamp = Math.round(Date.now() / 1000)
      }
      this.$emit('update:publishedAt', timestamp)
    }
  }
}
</script>

<style scoped>
  .checkbox {
    padding: 5px;
    width: min-content;
    height: min-content;
  }
</style>
