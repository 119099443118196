<template>
  <v-card outlined>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-lg-start align-md-center pa-2">
          <div class="caption pr-6"> № <b>{{ id }}</b></div>
          <div class="caption">
            <div
              v-if="createdAt"
              class="caption pr-6"
            >{{ $t('base.created_at') }}: <b>{{ formatTodayDate(createdAt) }}</b>
            </div>
            <div
              v-if="updatedAt"
              class="caption pr-6"
            >{{ $t('base.updated_at') }}: <b>{{ formatTodayDate(updatedAt) }}</b>
            </div>
            <div
              v-if="publishedBy"
              class="caption pr-6"
            >{{ $t('advert.published_by') }}: <b>{{ publishedBy.username }}</b>
            </div>
          </div>
          <div
            v-if="delayUntil"
            class="caption pa-2 mr-6 delay-until"
          >{{ $t('advert.delay_until') }}: <b>{{ timestampToDate(delayUntil) }}</b>
          </div>
          <AdvertExtra
            :value="advertExtra"
            @input="$emit('update:advertExtra', $event)"
          />
          <div class="pr-1 col-md-3">
            <v-select
              :value="exclusiveStatus"
              @input="$emit('update:exclusiveStatus', $event)"
              :items="advertExclusiveStatuses"
              item-value="id"
              item-text="value"
              no-data-text="Не установлено"
              :label="$t('advert.exclusive_status')"
            >
            </v-select>
            <!-- <v-checkbox
               dense
               v-if="exclusiveStatus"
               v-model="autoUpdatedAt"
               @change="$emit('update:autoUpdatedAt', $event)"
               class="mr-2"
               label="Автообновления"
             ></v-checkbox>-->
          </div>
          <div class="pr-4">
            <v-menu
              :close-on-content-click="false"
              :nudge-width="320"
              offset-x
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                >
                  <v-icon small>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <p
                        v-if="createdAt"
                        class="d-flex justify-md-space-between"
                      >
                        <span>{{ $t('base.created_at') }}</span> <b>{{ formatTodayDate(createdAt) }}</b>
                      </p>
                      <p class="d-flex justify-md-space-between">
                        <span>{{ $t('advert.user_in_charge') }} </span><b>{{
                          userInCharge ? userInCharge.username :
                            ''
                        }}</b>
                      </p>
                      <p class="d-flex justify-md-space-between">
                        <span>{{ $t('advert.source') }}</span> <b>{{ formatValue('advert.source', source) }}</b></p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div class="pr-4" v-if="id">
            <AdvertClients
              :local-key="localKey"
              :item-id="id"
            />
            <SearchAdvertCopy
              @detail="$emit('detail', $event)"
              :props-to-refresh="{ address, floor, roomCount, areaCommon, advertExternalIds }"
              :static-props="{ id, commonDesc }"
              :local-key="localKey"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import AdvertExtra from '@/components/views/admin/advert/Detail/Header/AdvertExtra'
import SearchAdvertCopy from '@/components/views/admin/advert/Detail/Header/AdvertCopy/SearchAdvertCopy'
import AdvertClients from '@/components/views/admin/advert/Detail/Header/AdvertClients/AdvertClients'

export default {
  components: { AdvertClients, SearchAdvertCopy, AdvertExtra },
  mixins: [formatters],
  name: 'Header',
  props: {
    id: Number,
    localKey: Number,
    createdAt: Number,
    updatedAt: Number,
    source: Number,
    userInCharge: Object,
    advertExtra: Array,
    delayUntil: Number,
    exclusiveStatus: Number,
    publishedBy: Object,
    autoUpdatedAt: Boolean,
    address: Object,
    floor: Number,
    roomCount: Number,
    areaCommon: String,
    advertExternalIds: Array,
    commonDesc: String
  },
  computed: {
    advertExclusiveStatuses () {
      return this.$store.getters['serviceData/optionsList']('advert.exclusive_status')
    }
  }
}
</script>

<style scoped>
.delay-until {
  background: gold;
}
</style>
