<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="localOptions"
    @update:options="$emit('input', $event)"
    :server-items-length="itemsLength"
    :loading="loading"
    :footer-props="{itemsPerPageOptions:  [20,100,300]}"
    multi-sort
  >
    <template v-slot:item="{ item }">
      <tr
        :class="advertClassByStatus(item)"
        @click="detail(item.id)"
      >
        <td>{{item.id}}</td>
        <td class="date">{{item.updated_at ? timestampToDatetime(item.updated_at) : ''}}</td>
        <td>
          <div
            v-for="phone in item.phones"
            :key="phone.phone"
            class="phone"
            :class="phoneStatusClass(phone.status)"
            style="white-space:nowrap"
          >
            {{maskValue(phone.phone, '8 ### ### ## ##')}}
          </div>
        </td>
        <td>{{item.building ? formatValue('building.district_custom', item.building.district_custom) : ''}}</td>
        <td>{{item.building ? formatValue('building.subdistrict', item.building.subdistrict) : ''}}</td>
        <td>{{item.building ? item.building.mainBuildingAddress.street.name + ' ' + item.building.mainBuildingAddress.building_number : ''}}
        </td>
        <td>{{item.room_count}}</td>
        <td>{{formatValue('advert.flat_type', item.flat_type)}}
        </td>
        <td>{{item.floor}}</td>
        <td>{{item.building ? item.building.total_floors : ''}}</td>
        <td>{{item.building ? formatValue('building.building_material', item.building.building_material) : ''}}</td>
        <td>{{item.area_common}}</td>
        <td>{{item.building ? item.building.build_year : ''}}</td>
        <td>{{formatPrice(item.price)}}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import headers from '@/components/views/admin/advert/Detail/Header/AdvertCopy/ListAdvertCopy/headers'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'

export default {
  mixins: [formatters, table],
  name: 'Table',
  data: () => ({
    headers: headers
  }),
  computed: {
    localOptions: {
      get () {
        return this.options
      },
      set (val) {
        this.$emit('update:options', val)
      }
    }
  }
}
</script>

<style scoped>
  .phone {
    min-width: 90px;
  }
  .date {
    width: 80px;
  }
</style>
