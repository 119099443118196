<template>
  <v-card
    class="controlsPanel pl-3 pr-3 grey lighten-4"
    max-width="71.1%"
  >
    <v-row dense>
      <v-col
        class="d-flex align-center align-self-center justify-space-around"
      >
        <slot name="default"></slot>
        <slot name="custom"></slot>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: 'ButtonBar'
}
</script>

<style scoped>
  .controlsPanel {
    position: fixed;
    z-index: 1;
    bottom: 0;
    min-height: 4em;
    right: 30px;
  }
</style>
