<template>
  <v-menu
    :value="value"
    @input="$emit('input', $event)"
    :close-on-content-click="false"
    :max-width="maxWidth"
    :min-width="minWidth"
    :left="left"
    :offset-x="left"
  >
    <template #activator="{ on }">
      <slot
        v-on="on"
        name="activator"
      >
        <nobr></nobr>
      </slot>
    </template>
    <v-card>
      <v-card-title
        class="primary white_text"
      >
        {{ tableTitle }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="scrollIfSoBig">
        <slot
          name="content"
        >
          <nobr></nobr>
        </slot>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  name: 'BaseRelatedTable',
  props: {
    tableTitle: String,
    value: Boolean,
    maxWidth: { type: String, default: '100%' },
    minWidth: { type: String },
    left: Boolean
  }
}
</script>

<style scoped>
.white_text {
  color: white !important;
}
.scrollIfSoBig {
  max-height: 35vh;
  overflow: auto;
}
</style>
