<template>
  <v-menu
    v-model="isOpenPhoneMenu"
    :close-on-content-click="false"
    :close-on-click="!isSavingPhone"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        dark
        class="primary pa-0 d-block"
        x-small
        @click="isOpenPhoneMenu = !isOpenPhoneMenu"
      >
        <v-icon x-small>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <div
        v-for="(phone, index) in localPhones"
        :key="index+'-'+localPhones.length+phone.phone.phone+phone.sort_order"
      >
        <PhoneInputSort
          :value="phone"
          @change="handlePhoneInputChange(index, $event)"
          :can-remove="localPhones.length > 1"
          :mask="phoneMask"
          @delete="handleRemovePhone(index)"
          input-class="pl-3"
          phone-cols="6"
          @save="savePhones"
        />
      </div>
      <!--Блок повтора номера-->
      <v-row>
        <v-col cols="12">
          <v-btn
            class="ml-2"
            small
            @click="handleAddPhone"
          >
            <v-icon>mdi-plus</v-icon>
            Добавить номер
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            class="primary"
            block
            small
            :loading="isSavingPhone"
            @click="savePhones"
          >Сохранить
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import PhoneInputSort from '@/components/views/admin/common/BasePhone/PhoneInputSort'
import phoneList from '@/components/mixins/phoneList'

export default {
  mixins: [phoneList],
  name: 'PhoneList',
  components: { PhoneInputSort },
  methods: {
    handlePhoneInputChange (index, data) {
      this.localPhones[index] = data
    }
  }
}
</script>

<style scoped>

</style>
