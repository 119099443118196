<template>
  <v-card
    outlined
    class="mt-1 pa-2"
  >
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <Autocomplete
              :value="localValues.address.street"
              @input="handleStreet($event)"
              item-value="streetId"
              item-text="name"
              :placeholder="$t('buildingAddress.street_id')"
              url="/street/autocomplete"
              :chars-to-search="2"
            />
          </v-col>
          <v-col cols="2">
            <!--todo: menu-props решение пункта 55 про размер окна автозаполнения-->
            <v-autocomplete
              :value="localValues.address"
              @input="handleBuildingNumber($event)"
              :items="buildingNumbers"
              :label="$t('buildingAddress.building_number')"
              item-value="id"
              item-text="building_number"
              :loading="buildingLoading"
              :disabled="!localValues.address.street"
              @focus="fillBuildingNumbers"
              return-object
              :cache-items="false"
              :menu-props="{maxHeight: 304}"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="flatNumber"
              @input="$emit('update:flatNumber', $event)"
              :label="$t('advert.flat_number')"
              class="pa-2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.42); min-height: 51px;">
               <span
                 class="caption"
                 style="opacity: 0.8; margin-top: -5px; display:block"
               >{{ $t('advert.raw_data') }}</span>
              <p v-html="rawData"></p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field
              :value="roomCount"
              @input="$emit('update:roomCount', Number($event))"
              :label="$t('advert.room_count')"
              class="pa-2"
              type="number"
              :error-messages="checkErrors('detail.room_count')"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              :label="$t('advert.flat_type')"
              class="pa-2"
              :value="flatType"
              @input="$emit('update:flatType', $event)"
              :items="flatTypeList"
              item-value="id"
              item-text="value"
              :error-messages="checkErrors('detail.flat_type')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="floor"
              @input="$emit('update:floor', Number($event))"
              :label="$t('advert.floor')"
              class="pa-2"
              type="number"
              :error-messages="checkErrors('detail.floor')"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              :label="$t('advert.flat_status')"
              class="pa-2"
              :value="flatStatus"
              @input="$emit('update:flatStatus', $event)"
              :items="flatStatusItemsWithEmpty"
              item-value="id"
              item-text="value"
              :error-messages="checkErrors('detail.flat_status')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="areaCommon"
              @input="$emit('update:areaCommon', $event)"
              :label="$t('advert.area_common')"
              class="pa-2"
              :error-messages="checkErrors('detail.area_common')"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="areaLiving"
              @input="$emit('update:areaLiving', $event)"
              :label="$t('advert.area_living')"
              class="pa-2"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="areaKitchen"
              @input="$emit('update:areaKitchen', $event)"
              :label="$t('advert.area_kitchen')"
              class="pa-2"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              :label="$t('advert.kitchen_type')"
              class="pa-2"
              :value="kitchenType"
              @input="$emit('update:kitchenType', $event)"
              :items="kitchenTypeItemsWithEmpty"
              item-value="id"
              item-text="value"
              :error-messages="checkErrors('detail.kitchen_type')"
            ></v-select>
          </v-col>
          <v-col
            cols="4"
            class="pa-1"
          >
            <AdvertExternalId v-model="localAdvertExternalIds"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import formatters from '@/components/mixins/formatters'
import { cleanClone } from '@/components/utils/common'
import validationErrors from '@/components/mixins/validationErrors'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import AdvertExternalId from '@/components/views/admin/advert/Detail/AdvertInfo/AdvertExternalId'

export default {
  mixins: [formatters, validationErrors, serviceDataComputeds],
  name: 'AdvertInfo',
  components: { AdvertExternalId, Autocomplete },
  props: [
    'address',
    'flatType',
    'flatNumber',
    'roomCount',
    'floor',
    'flatStatus',
    'priceHistory',
    'areaCommon',
    'areaLiving',
    'kitchenType',
    'areaKitchen',
    'planingType',
    'rawData',
    'advertExternalIds'
  ],
  data () {
    return {
      listCity: ['Ростов-на-Дону', 'Краснодар'],
      defaultCity: 'Ростов-на-дону',
      buildingLoading: false,
      buildingNumberList: cleanClone([this.address]),
      localValues: {
        address: cleanClone(this.address)
      }
    }
  },
  inject: ['$v'],
  watch: {
    'localValues.address': {
      handler: function (newVal, oldVal) {
        if (this.address.building_id !== this.localValues.address.building_id) {
          this.$emit('update:address', cleanClone(this.localValues.address))
        }
      },
      deep: true
    },
    address: {
      handler () {
        if (this.address.building_id && this.address.building_id !== this.localValues.address.building_id) {
          this.localValues.address = this.address
          this.buildingNumberList = [this.address]
        }
      },
      deep: true
    }
  },
  computed: {
    localAdvertExternalIds: {
      get () {
        return this.advertExternalIds
      },
      set (val) {
        this.$emit('update:advertExternalIds', val)
      }
    },
    flatTypeList () {
      let result = this.flatTypeItemsWithEmpty?.filter((item) => item.id !== 7)
      return result
    }
  },
  methods: {
    async fillBuildingNumbers () {
      if (this.localValues.address.street) {
        this.buildingLoading = true
        const response = await this.$store.dispatch('table/getServerData', { url: '/address/building-numbers?streetId=' + this.localValues.address.street.id })
        if (response) {
          this.buildingNumberList = response
        }
        this.buildingLoading = false
      }
    },
    handleBuildingNumber (data) {
      this.localValues.address.id = data.id
      this.localValues.address.building_number = data.building_number
      this.localValues.address.building_id = data.building_id
    },
    handleStreet (data) {
      this.localValues.address.street = data
      if (!(data && this.address.street && this.address.street.id === this.localValues.address.street.id)) {
        this.localValues.address.id = null
        this.localValues.address.building_number = null
        this.localValues.address.building_id = null
        this.buildingNumberList = []
      }
    }
  }
}
</script>

<style scoped>

</style>
