import UserPhone from '@/components/structures/userPhone'
import AdvertPhone from '@/components/structures/advertPhone'

class PhoneStructureFactory {
  constructor (name) {
    switch (name) {
      case 'advert':
        return new AdvertPhone()
      case 'client':
        return new UserPhone()
    }
  }
}

export default PhoneStructureFactory
