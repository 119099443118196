<template>
  <div class="caption pr-4 flex-grow-1"><b>{{$t('advert.burden')}}:</b>
    <!--Обременение чипс-->
    <span
      class="pa-2 ma-0"
      v-for="(item, index) in localAdvertExtras"
      :key="`${key}${index}`"
    >
      <v-menu
        max-width="200px"
        :close-on-content-click="false"
        :close-on-click="false"
        offset-x
        ref="edit"
      >
        <template v-slot:activator="{ on }">
          <v-chip
            :color="item.state ? 'red' : 'green'"
            outlined
            close
            x-small
            @click="on.click"
            @click:close="handleRemove(index)"
          >
            {{formatValue('advertExtra.name', item.name)}} - {{item.state ? 'Есть' : 'Нет'}}
          </v-chip>
        </template>
        <AdvertExtraDetail
          :value="item"
          @change="handleChange($event, index)"
          @close="handleInternalClose('edit', index)"
        />
      </v-menu>
    </span>
    <!--Обременение чипс-->
    <v-menu
      :close-on-content-click="false"
      :close-on-click="false"
      :nudge-width="100"
      offset-x
      ref="create"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          x-small
          icon
          v-on="on"
          class="grey lighten-4"
        >
          <v-icon x-small>mdi-plus</v-icon>
        </v-btn>
      </template>
      <AdvertExtraDetail
        :value="newAdvertExtra"
        @change="handleChange($event)"
        @close="handleInternalClose('create')"
      />
    </v-menu>
  </div>
</template>

<script>
import AdvertExtraDetail from '@/components/views/admin/advert/Detail/Header/AdvertExtraDetail'
import AdvertExtra from '@/components/structures/advertExtra'
import formatters from '@/components/mixins/formatters'
import { cleanClone, deepEqual } from '@/components/utils/common'

export default {
  mixins: [formatters],
  name: 'AdvertExtra',
  components: { AdvertExtraDetail },
  props: {
    value: Array
  },
  data () {
    return {
      localAdvertExtras: this.value,
      key: true
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal)) {
          this.localAdvertExtras = cleanClone(this.value)
        }
      },
      deep: true
    }
  },
  computed: {
    newAdvertExtra () {
      return new AdvertExtra()
    }
  },
  methods: {
    handleChange (data, index = null) {
      if (index !== null) {
        this.localAdvertExtras[index] = data
      } else {
        this.localAdvertExtras.push(data)
      }
      this.$emit('input', this.localAdvertExtras)
      this.key = !this.key
    },
    handleRemove (index) {
      this.localAdvertExtras.splice(index, 1)
    },
    //note: закрывает меню из ребенка без использования v-model
    handleInternalClose (name, index = null) {
      let ref = this.$refs[name]
      if (index !== null) {
        ref = ref[index]
      }
      ref.isActive = false
    }
  }
}
</script>

<style scoped>

</style>
