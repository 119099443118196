class AdvertExternalId {
  constructor () {
    this.id = null
    this.source = null
    this.source_id = null
    this.source_link = null
  }
}

export default AdvertExternalId
