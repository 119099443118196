<template>
  <v-container class="pt-2">
    <ListAdvertCopy
      v-model="openList"
      :title="title"
      :filters="filters"
      :advert-id="id"
      :on-click="true"
      @detail="$emit('detail', $event)"
    />
    <v-textarea
      v-model="localDesc"
      outlined
    ></v-textarea>
  </v-container>
</template>

<script>
import ListAdvertCopy from '@/components/views/admin/advert/Detail/Header/AdvertCopy/ListAdvertCopy'

export default {
  name: 'SearchByDescDefault',
  components: { ListAdvertCopy },
  props: {
    id: Number,
    commonDesc: String
  },
  data () {
    return {
      localDesc: this.commonDesc,
      title: 'по фрагменту текста',
      advertId: this.id,
      openList: false
    }
  },
  computed: {
    filters () {
      return { descriptionDefault: this.localDesc }
    }
  },
  methods: {
    async handleClick () {
      this.openList = false
      return this.$nextTick(() => {
        this.openList = true
      })
    }
  }
}
</script>

<style scoped>

</style>
