export default [
  {
    text: 'ID',
    align: 'left',
    sortable: true,
    value: 'id',
    width: '80px'
  },
  {
    text: 'ФИО',
    align: 'left',
    sortable: false,
    value: 'username',
    width: '80px'
  },
  {
    text: 'Телефон',
    align: 'left',
    sortable: false,
    value: 'phone',
    width: '100px'
  },
  {
    text: 'Отв. агент',
    align: 'left',
    sortable: true,
    value: 'parent_id',
    width: '100px'
  },
  {
    text: 'Снять',
    align: 'left',
    sortable: true,
    value: 'viewed',
    width: '15px'
  },
  {
    text: 'Ссылка',
    align: 'left',
    sortable: true,
    value: 'approved',
    width: '15px'
  },
  {
    text: 'Показ',
    align: 'left',
    sortable: true,
    value: 'offered',
    width: '15px'
  }
]
