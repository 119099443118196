<template>
  <!--todo: стилизовать-->
  <v-row class="d-flex align-center">
    <v-col :cols="phoneCols">
      <!--note: для Firefox в инпутах слушаем события с модификатором .self для корректной работы-->
      <PhoneInput
        :class="inputClass"
        :value="value.phone.phone"
        @change="handleChangePhone"
        @save="handleKeyupEnter"
        :mask="mask"
      />
    </v-col>
    <v-col cols="3">
      <v-text-field
        :value="phone.sort_order"
        @input.self="handleInputSortOrder"
        @keyup.enter="handleKeyupEnter"
        @blur="handleBlur"
        type="number"
        :label="$t('phone.sort_order')"
      ></v-text-field>
    </v-col>
    <v-col
      cols="3"
      v-if="canRemove"
    >
      <v-btn
        icon
        @click="$emit('delete')"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import PhoneInput from '@/components/views/admin/common/BasePhone/PhoneInput'
import { cleanClone } from '@/components/utils/common'

export default {
  name: 'PhoneInputSort',
  components: { PhoneInput },
  props: {
    value: Object,
    canRemove: Boolean,
    mask: String,
    inputClass: String,
    phoneCols: String
  },
  data: function () {
    return {
      phone: cleanClone(this.value),
      maskedPhone: cleanClone(this.value.phone.phone),
      clearValue: null
    }
  },
  methods: {
    handleChangePhone (data) {
      this.phone.phone.phone = data
      this.$emit('change', cleanClone(this.phone))
    },
    handleInputSortOrder (data) {
      this.phone.sort_order = data * 1
    },
    handleKeyupEnter () {
      this.handleChangePhone()
      this.$emit('save')
    },
    handleBlur () {
      this.$emit('change', cleanClone(this.phone))
    }
  }
}
</script>

<style scoped>

</style>
