<template>
  <div>
    <v-select
      v-model="ownerStatus"
      :items="ownerStatuses"
      item-value="id"
      item-text="value"
      no-data-text="Не установлено"
      class="pa-2"
      multiple
      :label="$t('phone.owner_status')"
    ></v-select>
    <v-textarea
      v-model="agentDescription"
      outlined
      :label="$t('phone.description')"
      rows="2"
    ></v-textarea>
    <v-textarea
      v-if="isAdvertPhone"
      v-model="itemDescription"
      outlined
      :label="$t('advertPhone.description')"
      rows="2"
    ></v-textarea>
    <v-select
      v-model="phoneStatus"
      :items="phoneStatuses"
      item-value="id"
      item-text="value"
      :placeholder="$t('phone.status')"
      :label="$t('phone.status')"
      class="pa-2"
    >
    </v-select>
    <v-text-field
      v-model="ownerName"
      :label="$t('phone.name')"
      class="pa-2"
    ></v-text-field>
  </div>
</template>

<script>
import phoneComputed from '@/components/mixins/phoneComputed'

export default {
  mixins: [phoneComputed],
  name: 'PhoneCustom'
}
</script>

<style scoped>

</style>
